<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'documents' })"
    >
      طلبات السحب
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">البيانات الشخصية</h5>
              </template>
              <b-row>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="الاسم بالعربي"
                    label="الاسم بالعربي"
                    v-model="documents.customer_name"
                    id="customer_name"
                    name="title"
                    validate="required"
                    :disabled="$route.name === 'show-withdrawal-requests'"
                  ></input-form>
                  <input-form
                    placeholder="الاسم باللغة الانجليزية"
                    label="الاسم باللغة الانجليزية"
                    v-model="documents.fee"
                    id="fee"
                    name="title"
                    validate="required"
                    :disabled="$route.name === 'show-withdrawal-requests'"
                  ></input-form>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="$route.name !== 'showDocuments'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >{{ buttonText }}</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../../services/index'
import commonMixin from '@/mixins/commonMixin'

export default {
  components: {},
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      loading: false,
      loadingSubmit: false,
      documents: {
        customer_name: '',
        fee: ''
      }
    }
  },
  computed: {
    buttonText () {
      return this.$route.name === 'editDocuments' ? 'تعديل' : 'اضافة'
    }
  },
  methods: {
    getdocuments () {
      api.getWithdrawalRequest(this.$route.params.id).then((res) => {
        this.documents = res.data
      })
    },
    onSubmit () {
      if (this.$route.name === 'editDocuments') {
        this.editDocument()
      } else {
        this.loadingSubmit = true
        api
          .postDocument(this.documents)
          .then((res) => {
            core.showSnackbar('success', 'تم اضافة ')
            this.loadingSubmit = false
            this.$router.push({ name: 'documents' })
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      }
    },
    editDocument () {
      this.loadingSubmit = true
      api
        .editDocument(this.$route.params.id, this.documents)
        .then(() => {
          this.loadingSubmit = false
          core.showSnackbar('success', 'تم تعديل بنجاح')
          this.$router.push({ name: 'documents' })
        })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getdocuments()
    }
  }
}
</script>
    <style scoped>
.setting-box-title {
  min-width: 140px;
}
.setting-box-inner {
  margin-bottom: 30px;
}
</style>
